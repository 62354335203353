<template>
  <app-page :toolbar="toolbar" :list="list" @loaded="onLoaded" />
</template>

<script>
import request from '@/utils/request';
import dayjs from 'dayjs';

export default {
  name: 'menuStatis1',
  data() {
    return {
      toolbar: {
        size: 'small',
        labelWidth: '60px',
        igrone: ['canteenType'],
        model: {
          orderDate: dayjs().format('YYYY-MM-DD'),
          canteenType: Number(Number(sessionStorage.getItem('canteenType')) !== 999 ? sessionStorage.getItem('canteenType') : 1),
        },
        fields: [
          {
            label: '日期',
            prop: 'orderDate',
            placeholder: '请选择要搜索的日期',
            type: 'date',
            clearable: true,
            valueFormat: 'yyyy-MM-dd',
            format: 'yyyy年MM月dd日',
            pickerOptions: { firstDayOfWeek: 1 }
          },
          {
            label: '食堂',
            prop: 'canteenType',
            placeholder: '请选择要搜索食堂',
            type: 'select',
            // clearable: true,
            options: [
              { label: '全部', value: undefined },
              { label: '一食堂', value: 1 },
              { label: '二食堂', value: 2 }
            ]
          }
        ]
      },
      list: {
        url: '/canteen/interface/appointmentStatistics',
        mode: 'all',
        action: false,
        pages: false,
        customRequest: this.customRequest,
        fields: [
          {
            label: '分类',
            prop: 'type',
            width: 120,
            formatter(row) {
              const values = ['', '早餐', '午餐 ', '晚餐', '外卖'];
              return values[row.type];
            }
          },
          {
            label: '已预约',
            prop: 'countQuantity',
            width: 120,
            align: 'center'
          },
          {
            label: '已使用',
            prop: 'completedQuantity',
            width: 120,
            align: 'center'
          },
          {
            label: '未使用',
            prop: 'bookedQuantity',
            width: 120,
            align: 'center'
          },
          {
            label: '明细',
            prop: 'menuList',
            width: 600,
            type: 'stats-menu'
          }
        ]
      }
    };
  },
  methods: {
    async customRequest(vm) {
      const params = { ...vm.defaultParams, params: vm.params };
      console.log(params);
      const result = await request.get(vm.url, { params });
      vm.items = result.data[0].statistics;
    },
    onLoaded(){
      if (sessionStorage.getItem('canteenType')){
        if (Number(sessionStorage.getItem('canteenType')) === 1){
          this.toolbar.fields[1].options = [{ label: '一食堂', value: 1 }]
        }else if (Number(sessionStorage.getItem('canteenType')) === 2){
          this.toolbar.fields[1].options = [{ label: '二食堂', value: 2 }]
        }
      }
    },
  }
};
</script>
