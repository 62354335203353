<template>
  <app-tabs v-model="active">
    <el-tab-pane label="订餐预约统计" lazy>
      <menu-stats-1 />
    </el-tab-pane>
    <el-tab-pane label="临时预约统计" lazy>
      <menu-stats-2 />
    </el-tab-pane>
    <el-tab-pane label="外卖预约统计" lazy>
      <menu-stats-3 />
    </el-tab-pane>
  </app-tabs>
</template>

<script>
import menuStats1 from './menuStats1';
import menuStats2 from './menuStats2';
import menuStats3 from './menuStats3';

export default {
  name: 'MenuStatistics',
  components: { menuStats1, menuStats2, menuStats3 },
  data() {
    return {
      active: '0'
    };
  }
};
</script>
